export default [
  {
    attribute: 'taxonomies.Tilbud',
    labelKey: 'tilbudLabel',
    name: 'Tilbud',
    vs:'showthis',
  },
  {
    attribute: 'taxonomies.Kommune',
    labelKey: 'kommuneLabel',
    name: 'Kommune',
    vs:'showthis',
  },
];

/* eslint-disable prettier/prettier */
/* global svvSearchForm */

import altidenSelects from './altiden-2022';
import stendiSelects from './stendi-website';

const localize = function(key) {
  if (typeof svvSearchForm === 'undefined') {
    return false;
  }

  if (typeof svvSearchForm[key] === 'undefined') {
    return false;
  }

  return svvSearchForm[key];
};

const defaultSelects = [
  {
    attribute: 'taxonomies.Tjänst',
    labelKey: 'tjanstLabel',
    name: 'Tjanst',
    orderOn:localize('search_ServicesortOn') || 'name',
    vs:'showthis'
  },
  {
    attribute: 'taxonomies.Län',
    labelKey: 'lanLabel',
    name: 'Lan',
    orderOn:localize('search_RegionsortOn') || 'name' 
  },
  {
    attribute: 'taxonomies.kommun',
    labelKey: 'kommunLabel',
    name: 'Kommun',
    orderOn:localize('search_MunicipalitysortOn') || 'name'
  }
]

var exportedSelects = defaultSelects;

const theme = localize('svb_active_theme') || '';

if (theme === 'altiden-2022') {
  exportedSelects = altidenSelects;
}
if (theme === 'stendi-website') {
  exportedSelects = stendiSelects;
}

export default exportedSelects;
